import React, { useMemo } from 'react';
import { useFormik } from 'formik';

// MUI Imports
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import {
  Autocomplete,
  Chip,
  DialogActions,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import AddLocation from '../addLocation/AddLocation';
import { adminLocations, modifyUsers } from '../../../redux/userActions';
import { callPopup } from '../../../store/slices/popupSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function AssignRole(props) {
  const {
    onClose,
    orgLevels,
    userId,
    userLevel,
    roles,
    setAnalyticUpdated,
    isUpdated,
    open,
    ...other
  } = props;

  const [openPopup, setOpenPopup] = React.useState(false);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const [locations, setLocations] = React.useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};
    if (!values.orgLevelValue) errors.orgLevelValue = t('required');

    if (!values.location) errors.location = t('required');

    if (!values.role) errors.role = t('required');

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      orgLevelValue: userLevel || '',
      location: [],
      role: '',
    },
    validate,
    onSubmit: async (values) => {
      try {
        const res = await modifyUsers({
          userId: userId,
          status: 'ACTIVE',
          locationIds: values.location,
          roleId: values.role,
          orgLevel: values.orgLevelValue,
        });

        if (res.success === true) {
          handlePopupClose();
          dispatch(
            callPopup({
              open: true,
              type: 'success',
              page: 'assign-role',
              message: t('user_approved'),
            })
          );
          setAnalyticUpdated((prev) => !prev);
        } else {
          handlePopupClose();
          dispatch(
            callPopup({
              open: true,
              type: 'error',
              page: 'assign-role',
              title: res.data.message,
              message: res.data.data,
            })
          );
        }
      } catch (error) {
        console.error('API Call Error:', error);
      }
    },
  });

  const handlePopupClose = () => {
    formik.resetForm();
    onClose();
  };

  React.useEffect(() => {
    formik.setFieldValue('orgLevelValue', userLevel);
  }, [userLevel, isUpdated]);

  React.useEffect(() => {
    fetchData(formik?.values?.orgLevelValue);
  }, [formik?.values?.orgLevelValue]);
  const fetchData = async (org) => {
    if (org) {
      const res = await adminLocations(org);
      setLocations(res?.data?.data);
    }
  };

  const districtOptions = useMemo(
    () =>
      locations?.map((district) => ({
        id: district?._id,
        name: district?.name,
      })),
    [locations]
  );

  return (
    <React.Fragment>
      <Dialog
        sx={{
          '& .MuiDialog-paper': { width: '100%' },
        }}
        className='ModalPopup__dialog'
        maxWidth='sm'
        open={open}
        {...other}
      >
        <DialogTitle sx={{ padding: '0 !important' }}>
          <div className='ModalPopup__header'>
            <div className='ModalPopup__header__title_fs'>
              {t('assign_location_title')}
            </div>
            <div className='ModalPopup__close_icon' onClick={handlePopupClose}>
              <i className='fa-solid fa-xmark'></i>
            </div>
          </div>
        </DialogTitle>

        <form
          className='ModalPopup__form_container'
          onSubmit={formik.handleSubmit}
        >
          <DialogContent
            sx={{ padding: '0 !important', borderRadius: '0.6rem !important' }}
          >
            <div className='ModalPopup__wrapper'>
              <div className='ModalPopup__body min_height'>
                <div className='ModalPopup__form_section'>
                  <div className='ModalPopup__label_header'>
                    <h1 className='ModalPopup__form_section_heading_fs'>
                      {t('assign_location_subtitle')}
                    </h1>
                    <button
                      className='mi_btn mi_btn_link'
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenPopup();
                      }}
                    >
                      <i className='fa-solid fa-plus'></i>
                      <span> {t('add_loc_button')} </span>
                    </button>
                  </div>

                  <div className='Input__table'>
                    <div className='Input__row'>
                      <div className='Input__column'>
                        <p className='Input__label_fs'>
                          {t('org_level')} <b>*</b>
                        </p>

                        <FormControl fullWidth>
                          <Select
                            className='mui_custom_input_field'
                            size='small'
                            id='orgLevelValue'
                            name='orgLevelValue'
                            onChange={formik.handleChange}
                            value={
                              formik.values.orgLevelValue !== undefined
                                ? formik.values.orgLevelValue
                                : ''
                            }
                          >
                            {orgLevels?.map((org, index) => (
                              <MenuItem key={index} value={org?.value}>
                                {org?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.errors.orgLevelValue &&
                            formik.touched.orgLevelValue && (
                              <span className='Input__error_fs'>
                                {formik.errors.orgLevelValue}
                              </span>
                            )}
                        </FormControl>
                      </div>
                    </div>
                    <div className='Input__row'>
                      <div className='Input__column'>
                        <p className='Input__label_fs'>
                          {t('location')} <b>*</b>
                        </p>

                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            id='tags-filled'
                            size='small'
                            className='mui_custom_autocomplete'
                            options={districtOptions}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                              option?.id === value?.id
                            }
                            value={districtOptions?.filter((option) =>
                              formik?.values?.location?.includes(option?.id)
                            )}
                            onChange={(event, value) => {
                              formik.setFieldValue(
                                'location',
                                value.map((option) => option?.id)
                              );
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant='outlined'
                                  label={option?.name}
                                  {...getTagProps({ index })}
                                  key={option?.id}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className='mui_custom_autocomplete'
                                size='small'
                                variant='outlined'
                                placeholder={
                                  formik?.values?.location?.length > 0
                                    ? ''
                                    : 'Select locations'
                                }
                                sx={{ borderRadius: '8px' }}
                              />
                            )}
                          />
                          {/* <Select
                            multiple
                            className="mui_custom_input_field"
                            size="small"
                            placeholder="Select your Organization Level"
                            id="location"
                            name="location"
                            onChange={formik.handleChange}
                            value={formik.values.location}
                          >
                            {locations?.map((loc, index) => (
                              <MenuItem key={index} value={loc._id}>
                                {loc.name}
                              </MenuItem>
                            ))}
                          </Select> */}
                          {formik.errors.location &&
                            formik.touched.location && (
                              <span className='Input__error_fs'>
                                {formik.errors.location}
                              </span>
                            )}
                        </FormControl>
                      </div>
                    </div>
                    <div className='Input__row'>
                      <div className='Input__column'>
                        <p className='Input__label_fs'>
                          {t('user_role')} <b>*</b>
                        </p>

                        <FormControl fullWidth>
                          <Select
                            className='mui_custom_input_field'
                            size='small'
                            id='role'
                            name='role'
                            onChange={formik.handleChange}
                            value={formik.values.role}
                          >
                            {roles?.map((level, index) => (
                              <MenuItem key={index} value={level._id}>
                                {level?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.errors.role && formik.touched.role && (
                            <span className='Input__error_fs'>
                              {formik.errors.role}
                            </span>
                          )}
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className='ModalPopup__action_wrapper'>
              <div className='ModalPopup_actions'>
                <button
                  type='submit'
                  className='mi_btn mi_btn_medium mi_btn_primary'
                >
                  <span>{t('accept_button')}</span>
                </button>
              </div>
            </div>
          </DialogActions>
        </form>
      </Dialog>

      <AddLocation
        keepMounted
        open={openPopup}
        locations={locations}
        orgLevels={orgLevels}
        userId={userId}
        onClose={handleClosePopup}
      />
    </React.Fragment>
  );
}
